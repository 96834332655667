<template>
    <div class="flex align-items-center py-5 px-3">
        <i class="pi pi-fw pi-money-bill mr-2 text-2xl" />
        <p class="m-0 text-lg">Payment Component Content via Child Route</p>

        <p class="m-0 text-lg">&nbsp;&nbsp;</p>
        <a href="#/menu/confirmation" @click="gotoPage('Confirmation')" class="p-menuitem-link">
            Confirmation
        </a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nestedRouteItems: null,
        };
    },
    created() {
        console.log('Payment')
        console.log('current page is ' + window.sessionStorage.getItem('currPage'));
        console.log(this.$route.query)

        var jsonStr = window.sessionStorage.getItem('breadItems')
        this.nestedRouteItems = JSON.parse(jsonStr);
    },
    methods: {
        gotoPage(to) {
            console.log('now change to ' + to);

            this.nestedRouteItems.push({
                label: to,
                to: '/menu/confirmation',
                params:{
                    param1: 'con_1p',
                    param2: 5
                }
            });

            window.sessionStorage.setItem('currPage', to);
            window.sessionStorage.setItem('breadItems', JSON.stringify(this.nestedRouteItems));

            this.$emit('updateData');
            return true;
        },
    },
}
</script>